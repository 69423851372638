// Variables
:root {
	--primary-blue-50: #f9fcff;
	--primary-blue-100: #e9f3fe;
	--primary-blue-200: #cde4fd;
	--primary-blue-300: #a6d0fc;
	--primary-blue-400: #75b5fa;
	--primary-blue-500: #3895f8;
	--primary-blue-700: #05458a;
	--primary-blue-800: #032c59;
	--primary-blue-900: #021e3b;

	--secondary-blue-100: #f1f5fa;

	--secondary-dark-grey: #999999;
	--secondary-grey: #b8b8b8;

	--gray-100: #ececec;
	--gray-200: #e1e1e1;
	--gray-300: #c9c9c9;
	--gray-400: #aaaaaa;
	--gray-600: #595959;

	--status-red: #fe2720;
	--status-amber: #fe5f20;
	--status-green: #38f03e;
	--status-blue: var(--primary-blue-500);

	--white: #fff;
	--black: #1e1e1e;
}

// Bootstrap Colours
$primary: #05458a;

// Bootstrap Offcanvas
$offcanvas-bg-color: var(--primary-blue-900);

// Bootstrap Accordion
$accordion-button-color: var(--primary-blue-700);
$accordion-button-active-color: var(--primary-blue-700);
$accordion-button-padding-y: 8px;
$accordion-button-padding-x: 10px;
$accordion-transition: unset;

// Bootstrap Nav
$nav-underline-link-active-color: var(--primary-blue-700);
$nav-link-color: var(--primary-blue-500);

// Bootstrap Popover
$popover-header-bg: var(--primary-blue-700);
$popover-header-color: white;

// Custom Colours

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';

$custom-colors: (
	'serveox': #05458a,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Light mode
$custom-colors-text: (
	'serveox': #05458a,
);
$custom-colors-bg-subtle: (
	'serveox': #023367,
);
$custom-colors-border-subtle: (
	'serveox': #023367,
);

$theme-colors-text: map-merge($theme-colors-text, $custom-colors-text);
$theme-colors-bg-subtle: map-merge($theme-colors-bg-subtle, $custom-colors-bg-subtle);
$theme-colors-border-subtle: map-merge($theme-colors-border-subtle, $custom-colors-border-subtle);

// Dark mode
$custom-colors-text-dark: (
	'serveox': #05458a,
);
$custom-colors-bg-subtle-dark: (
	'serveox': #023367,
);
$custom-colors-border-subtle-dark: (
	'serveox': #023367,
);

$theme-colors-text-dark: map-merge($theme-colors-text-dark, $custom-colors-text-dark);
$theme-colors-bg-subtle-dark: map-merge($theme-colors-bg-subtle-dark, $custom-colors-bg-subtle-dark);
$theme-colors-border-subtle-dark: map-merge($theme-colors-border-subtle-dark, $custom-colors-border-subtle-dark);

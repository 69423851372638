/* You can add global styles to this file, and also import other style files */
@import './variables.scss';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@font-face {
	font-family: 'Prompt';
	src: url('assets/fonts/Prompt/Prompt-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Prompt';
	src: url('assets/fonts/Prompt/Prompt-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Prompt';
	src: url('assets/fonts/Prompt/Prompt-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Inter';
	src: url('assets/fonts/Inter/Inter_18pt-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Google Places Autocomplete
.pac-container {
	z-index: 1056 !important;
}

// Ng-Select Form Validation
ng-select.ng-invalid.ng-touched {
	.ng-select-container {
		border-color: var(--bs-form-invalid-border-color);
		box-shadow:
			inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 0 3px #fde6e8;
	}
}

.popover {
	--bs-popover-bg: var(--primary-blue-300);
	--bs-popover-arrow-height: 16px;
	--bs-popover-arrow-width: 40px;
	--bs-popover-max-width: auto;
}

// angular-calendar Styles

// Angular Calendar Day View
mwl-calendar-event-title {
	height: 100%;

	.cal-event-title {
		display: flex;
		height: 100%;
		align-items: center;
	}
}

// Month calendar cell current date hover styles
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-day-cell.cal-today {
	background-color: #fff !important;
}

.cal-day-view mwl-calendar-week-view-header {
	display: unset !important;
}

.cal-week-view .cal-day-headers span {
	opacity: unset !important;
}

textarea.form-control {
	flex-grow: 1;
	height: 100%;

	@media (max-width: 768px) {
		font-size: 14px !important;
		height: 6rem;
	}
}

.cal-day-headers {
	position: sticky;
	top: 0;
	z-index: 110;
	background-color: white;
	border-top: solid 1px #e1e1e1 !important;
}

.cal-header {
	position: sticky;
	top: 0;
	z-index: 110;
	background-color: white;
	border-bottom: solid 1px #e1e1e1 !important;
}
